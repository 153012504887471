@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.intro-container:hover {
  animation: animate 0.5s;
  cursor: pointer;
}

#sub-intro {
  line-height: 2rem;
}

@keyframes animate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes animateSubIntro {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

.social-link {
  color: white;
}

.social-link:hover {
  animation: animate 0.5s;
}

#social-text {
  transition: all 0.2s ease-in-out;
}

#social-text:hover {
  transform: scale(1.2);
}

.skill-box {
  transition: all 0.2s ease-in-out;
}

.skill-box:hover {
  cursor: default;
  transform: scale(1.05);
}

.view-projects-button {
  transition: all 0.2s ease-in-out;
}

.view-projects-button:hover {
  transform: scale(1.1);
}

.nervous-coder:hover {
  cursor: pointer;
}

.nervous-coder {
  animation: animateNervousCoder 800ms 500ms backwards;
}

@keyframes animateNervousCoder {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.wave {
  display: inline-block;
  animation: animate-hand-wave 500ms 2s 5 ease-in-out;
}

@keyframes animate-hand-wave {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0);
  }
}

.pacman-loader {
  margin-top: 25%;
  background-color: rgb(15 23 42);
}

#experience,
#projects,
#skills,
#contact {
  scroll-margin: 90px;
}
